import { useEffect } from "react";
import { isBrowser } from "react-device-detect";
import { useSearchParams } from "react-router-dom";
import {
    WEBAPP_URL,
    desktopWhatsAppURL,
    mobileWhatsAppURL,
} from "../constants";

export default function InviteToJoin() {
    const [searchParams] = useSearchParams();
    const listName = searchParams.get("lname");
    const listId = searchParams.get("lid");

    const inviteText = listName
        ? `בואו תצטרפו לרשימה שלי - "${listName}"\nמצטרפים כאן: ${WEBAPP_URL}/join/${listId}`
        : `בואו תצטרפו לרשימה שלי!\nמצטרפים כאן: ${WEBAPP_URL}/join/${listId}`;

    const inviteLink = encodeURI(
        (isBrowser ? desktopWhatsAppURL : mobileWhatsAppURL) + inviteText
    );

    useEffect(() => {
        if (!listId) return;
        window.location.replace(inviteLink);
    }, [inviteLink, inviteText, listId]);

    if (!listId) {
        return (
            <div className="grid place-content-center text-2xl mt-20">
                נראה שיש בעיה עם הקישור שקיבלתם, בקשו שישלחו לכם שוב
            </div>
        );
    }

    return (
        <div
            dir="ltr"
            className="font-poppins flex flex-col text-center justify-start gap-20 text-3xl w-full h-screen"
        >
            <div className="mt-40 lg:mt-40">Inviting to Sally 🥁</div>
            <div className="font-assistant text-lg mt-60 lg:mt-40">
                <h6>
                    אם לא קורה כלום, לחצו{" "}
                    <a href={inviteLink} className="underline text-green-500">
                        כאן
                    </a>
                </h6>
            </div>
        </div>
    );
}
