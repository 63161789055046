import { Listbox, Transition } from "@headlessui/react";
import {
    CheckIcon,
    ChevronDownIcon,
    PaperAirplaneIcon,
    PlusIcon,
} from "@heroicons/react/24/solid";
import { Spinner } from "components/Loader";
import { addItemToList } from "db-utils";
import useAlert from "hooks/useAlert";
import useList from "hooks/useList";
import useUser from "hooks/useUser";
import { Fragment, useEffect, useRef, useState } from "react";
import { CATEGORIES, getCategoryById } from "../../categories";

export default function AddItemPopup() {
    const [isOpen, setIsOpen] = useState(false);
    const { listData } = useList();
    const { user } = useUser();
    const { isAlerted } = useAlert();
    const [valueText, setValueText] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                inputRef.current?.focus();
            }, 0);
        }
    }, [isOpen, inputRef]);

    if (!listData || !user || !user?.userId) return null;

    const { items: existingItems, id: listId, type } = listData;
    const { userId, userData } = user;

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            setIsLoading(true);
            const value = valueText.trim();
            if (!value) {
                await isAlerted("שם הפריט לא יכול להיות ריק");

                return;
            }
            const isNewTextNumeric = !isNaN(Number(value));
            if (isNewTextNumeric) {
                await isAlerted("שם הפריט לא יכול להיות ספרה בלבד");
                return;
            }
            const isNewTextListWord = value === "רשימה";
            if (isNewTextListWord) {
                await isAlerted('שם הפריט לא יכול להיות "רשימה"');
                return;
            }
            if (
                existingItems.some(
                    (item) =>
                        item.text === value && !item.checked && !item.missing
                )
            ) {
                await isAlerted("פריט עם שם זהה כבר קיים ברשימה");
                return;
            }

            const succeeded = await addItemToList({
                itemsText: value,
                listId,
                userId,
                token: userData.token,
            });
            if (!succeeded) {
                await isAlerted("אירעה שגיאה בהוספת הפריט");
            } else {
                setValueText("");
            }
        } catch (error) {
            console.error(error);
            await isAlerted("אירעה שגיאה בהוספת הפריט");
        } finally {
            setIsLoading(false);
            // closeModal();
            window.scrollTo(0, 0);
        }
    };

    function closeModal() {
        setValueText("");
        setIsOpen(false);
    }

    function openModal() {
        setIsOpen(true);
    }

    return (
        <>
            <div className="flex items-center w-full gap-1">
                <button
                    type="button"
                    onClick={isOpen ? closeModal : openModal}
                    className={`flex items-center rounded-full transition-all duration-300 shrink-0 justify-center focus:outline-none  ${
                        isOpen
                            ? "rotate-135 bg-white text-slate-700 h-10 w-10 p-1"
                            : "rotate-0 text-white bg-green-500 h-14 w-14 p-2"
                    }`}
                >
                    <PlusIcon className="h-8 w-8" />
                </button>
                <Transition show={isOpen} as={Fragment}>
                    <Transition.Child
                        enter="transition ease-out duration-100"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                        className="flex items-center justify-between w-full"
                    >
                        <form
                            onSubmit={(e) => {
                                onSubmit(e);
                            }}
                            className="flex items-center justify-between w-full gap-1"
                        >
                            <input
                                type="text"
                                placeholder="ביצים, 3 חלב, ..."
                                className="w-full h-12 px-4 text-lg font-medium text-slate-800 bg-slate-100 rounded-full focus:outline-none disabled:text-slate-300"
                                onChange={(e) => {
                                    setValueText(e.target.value);
                                }}
                                ref={inputRef}
                                value={valueText}
                                disabled={isLoading}
                            />
                            <button
                                type="submit"
                                disabled={!valueText || isLoading}
                                className="flex items-center rounded-full justify-center p-2.5 shrink-0 focus:outline-none h-10 w-10 group bg-white"
                            >
                                {isLoading ? (
                                    <Spinner size="sm" />
                                ) : (
                                    <PaperAirplaneIcon className="h-7 w-7 transition-all rotate-180 shrink-0 text-green-500 group-disabled:text-slate-300 group-disabled:h-6 group-disabled:w-6" />
                                )}
                            </button>
                        </form>
                    </Transition.Child>
                </Transition>
            </div>
        </>
    );
}

const CategoriesSelectList = ({
    selected,
    setSelected,
}: {
    selected: string;
    setSelected: (category: string) => void;
}) => {
    const category = getCategoryById({ id: selected });
    return (
        <div className="">
            <label
                htmlFor="item-name"
                className="block text-xl font-medium text-slate-800"
            >
                קטגוריה
            </label>
            <Listbox value={selected} onChange={setSelected}>
                <div className="relative mt-1">
                    <Listbox.Button className="relative block w-full text-lg px-2 focus:outline-none shadow-sm h-12 border border-slate-300 mt-1 focus:border-slate-800 rounded-md">
                        <div className="flex text-xl font-medium items-center gap-3 w-full py-2 truncate">
                            {category.icon}
                            <span>{category.title}</span>
                        </div>
                        <span className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
                            <ChevronDownIcon
                                className="h-5 w-5 text-slate-800"
                                aria-hidden="true"
                            />
                        </span>
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options className="z-40 -top-32 absolute mt-1 max-h-96 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-slate-900/5 focus:outline-none">
                            {Object.values(CATEGORIES).map((category) => (
                                <Listbox.Option
                                    key={category.id}
                                    className={({ active }) =>
                                        `relative cursor-default select-none py-2 px-4 ${
                                            active
                                                ? "bg-green-100"
                                                : "text-slate-800"
                                        }`
                                    }
                                    value={category.id}
                                >
                                    {({ selected }) => (
                                        <>
                                            <div key={category.id}>
                                                <div className="flex text-xl font-medium items-center gap-3 w-full py-2">
                                                    {category.icon}
                                                    <span>
                                                        {category.title}
                                                    </span>
                                                </div>
                                            </div>

                                            {selected ? (
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-slate-800">
                                                    <CheckIcon
                                                        className="h-5 w-5"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                            ) : null}
                                        </>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
        </div>
    );
};
