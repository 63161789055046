import React from "react";

export default function Cancel() {
    return (
        <div>
            {" "}
            <p
                className="MsoListParagraphCxSpMiddle"
                dir="RTL"
                style={{
                    marginTop: "0cm",
                    marginRight: "18.0pt",
                    marginBottom: "0cm",
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-18.0pt",
                    lineHeight: "115%",
                    background: "#C9F7D6",
                    verticalAlign: "baseline",
                    direction: "rtl",
                    unicodeBidi: "embed",
                }}
            >
                <b>
                    <span
                        style={{
                            fontSize: "10.0pt",
                            lineHeight: "115%",
                            color: "black",
                        }}
                    >
                        5.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                </b>
                <span dir="RTL" />
                <a
                    href="#cancel"
                    lang="HE"
                    style={{
                        fontSize: "10.0pt",
                        lineHeight: "115%",
                        color: "black",
                    }}
                >
                    <b>מדיניות ביטול עסקה</b>
                </a>
            </p>
            <p
                className="MsoListParagraph"
                dir="RTL"
                style={{
                    marginTop: "6.0pt",
                    marginRight: "26.1pt",
                    marginBottom: "3.0pt",
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-26.1pt",
                    lineHeight: "115%",
                    verticalAlign: "baseline",
                    direction: "rtl",
                    unicodeBidi: "embed",
                }}
            >
                <span style={{ fontSize: "10.0pt", lineHeight: "115%" }}>
                    5.1.
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span dir="RTL" />
                <span
                    lang="HE"
                    style={{ fontSize: "10.0pt", lineHeight: "115%" }}
                >
                    אם ביצעת עסקה בה הצטרפת לשירותים בתשלום, זכותך לבטל את
                    ההתקשרות בפניה בכתב שתמסור לנו תוך 14 ימים מהיום בו בוצעה
                    ההתקשרות או מיום קבלת מסמך בכתב בנוגע להתקשרות, לפי המאוחר
                    מבניהם. במקרה שהעסקה אינה "עסקה מתמשכת" כהגדרתה בחוק הגנת
                    הצרכן, עליך לבטל את ההתקשרות יומיים לפחות לפני תחילת מתן
                    השירות שהזמנת. במקרה של ביטול של עסקה שהיא "עסקה מתמשכת"
                    שהתחלנו במתן השירות לפיה, אנו נחזיר לך את הסכום שכבר שילמת
                    לנו, ונחייב אותך בתמורה היחסית בעד השירות שניתן לך
                </span>
                <span
                    lang="HE"
                    style={{ fontSize: "10.0pt", lineHeight: "115%" }}
                >
                    .
                </span>
            </p>
            <p
                className="MsoListParagraph"
                dir="RTL"
                style={{
                    marginTop: "6.0pt",
                    marginRight: "26.1pt",
                    marginBottom: "3.0pt",
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-26.1pt",
                    lineHeight: "115%",
                    verticalAlign: "baseline",
                    direction: "rtl",
                    unicodeBidi: "embed",
                }}
            >
                <span style={{ fontSize: "10.0pt", lineHeight: "115%" }}>
                    5.2.
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span dir="RTL" />
                <span
                    lang="HE"
                    style={{ fontSize: "10.0pt", lineHeight: "115%" }}
                >
                    כמו כן, נהיה רשאים לחייב אותך בדמי ביטול בגובה 5% ממחיר
                    העסקה או 100 ₪, לפי הנמוך מבניהם, או סכום אחר שייקבע בחוק.
                </span>
            </p>
            <p
                className="MsoListParagraph"
                dir="RTL"
                style={{
                    marginTop: "6.0pt",
                    marginRight: "26.1pt",
                    marginBottom: "3.0pt",
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-26.1pt",
                    lineHeight: "115%",
                    verticalAlign: "baseline",
                    direction: "rtl",
                    unicodeBidi: "embed",
                }}
            >
                <span style={{ fontSize: "10.0pt", lineHeight: "115%" }}>
                    5.3.
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span dir="RTL" />
                <span
                    lang="HE"
                    style={{ fontSize: "10.0pt", lineHeight: "115%" }}
                >
                    במקרה בו הנך מעוניין בביטול עסקה שביצעת, תוכל לפנות אלינו
                    בצירוף בקשתך לביטול העסקה ובציון שמך המלא ומספר הטלפון
                    שלגביו הנך מבקש לבטל את העסקה, באחת הדרכים הבאות:
                    <a>באמצעות טופס ביטול עסקה באתר, </a>
                    בוואטסאפ: 058-7501168, או דוא״ל:{" "}
                </span>
                <a href="mailto:contact@heysally.co.il">
                    <span
                        dir="LTR"
                        style={{ fontSize: "10.0pt", lineHeight: "115%" }}
                    >
                        contact@heysally.co.il
                    </span>
                </a>
                <span dir="RTL" />
                <span
                    lang="HE"
                    style={{ fontSize: "10.0pt", lineHeight: "115%" }}
                >
                    <span dir="RTL" />.
                </span>
            </p>
            <p
                className="MsoListParagraph"
                dir="RTL"
                style={{
                    marginTop: "6.0pt",
                    marginRight: "26.1pt",
                    marginBottom: "3.0pt",
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "-26.1pt",
                    lineHeight: "115%",
                    verticalAlign: "baseline",
                    direction: "rtl",
                    unicodeBidi: "embed",
                }}
            >
                <span style={{ fontSize: "10.0pt", lineHeight: "115%" }}>
                    5.4.
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span dir="RTL" />
                <span
                    lang="HE"
                    style={{ fontSize: "10.0pt", lineHeight: "115%" }}
                >
                    ככל שהנך צרכן שהוא אדם עם מוגבלות, אזרח ותיק או עולה חדש,
                    הנך רשאי על פי החוק לבטל עסקת מכר מרחוק בתוך 4 חודשים ממועד
                    עשיית העסקה, לפי המאוחר, ובלבד שההתקשרות בעסקה כללה שיחה
                    בינך ובין העוסק, ובכלל זה שיחה באמצעות תקשורת אלקטרונית. אנו
                    נהיה רשאים לבקש ממך תעודה המוכיחה כי הנך אדם עם מוגבלות,
                    אזרח ותיק או עולה חדש.
                </span>
            </p>
        </div>
    );
}
