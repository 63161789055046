import { BOT_PHONE_NUMBER, registerToBotURL } from "../constants";

export default function LoginPage() {
    return (
        <div className="px-4 mt-20 flex flex-col gap-20 max-w-[400px] mx-auto">
            <div className="text-slate-800 text-3xl font-semibold text-center">
                <h1 className="font-poppins text-4xl">
                    <span className="font-assistant">סאלי</span>
                    Sally
                </h1>
                התחברות
            </div>
            <div className="flex flex-col gap-5 justify-center items-center text-center">
                <h2 className="text-2xl font-medium">
                    להתחברות יש להיכנס דרך הקישור שקיבלתם בוואטסאפ
                </h2>
                <a
                    href={encodeURI(
                        `https://wa.me/${BOT_PHONE_NUMBER}?text=אתר הרשימה`
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-primary-lg !text-xl w-full mt-5"
                >
                    התחברות דרך הוואטסאפ
                </a>
            </div>
            <div className="flex flex-col lg:flex-row gap-2 justify-center items-center text-center text-xl lg:text-2xl font-medium">
                <h3>חיפשתם איך מצטרפים לבוט?</h3>
                <a
                    href={registerToBotURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline text-green-500"
                >
                    לחצו כאן
                </a>
            </div>
        </div>
    );
}
