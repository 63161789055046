import {
    ArrowUturnRightIcon,
    CheckIcon,
    EllipsisVerticalIcon,
    PencilIcon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import TrashIcon from "components/icons/TrashIcon";
import {
    deleteItemFromDB,
    deleteSavedItemFromDB,
    getListData,
    updateItemInDB,
    voteForCategory,
} from "db-utils";
import useAlert from "hooks/useAlert";
import useConfirm from "hooks/useConfirm";
import useList from "hooks/useList";
import useUser from "hooks/useUser";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { useLongPress } from "use-long-press";
import { getUnitShortText, isPremium } from "utils";
import { CATEGORIES, getCategoryById } from "../../categories";
import { LONG_PRESS_VIBRATE } from "../../constants";
import { Item, LIST_TYPES } from "../../types";
import SelectCategoryPopup from "../list/SelectCategoryPopup";

export default function ListItem({
    text,
    checked,
    missing,
    categoryId,
    quantity,
    unit,
    comments,
    saved,
    addedBy,
    isSavedItemsMode = false,
    isSelectedSavedItem = false,
    isInListAlready = false,
    onSavedItemSelect,
    onItemLongPress,
    setShowConfetti,
    onEditClick,
    ...restOfItem
}: Item & {
    isSavedItemsMode?: boolean;
    isSelectedSavedItem?: boolean;
    isInListAlready?: boolean;
    onSavedItemSelect?: ({
        itemId,
        itemText,
    }: {
        itemId: string;
        itemText: string;
    }) => void;
    onItemLongPress?: () => void;
    setShowConfetti?: React.Dispatch<React.SetStateAction<boolean>>;
    onEditClick?: () => void;
}) {
    const { listData } = useList();
    const { user } = useUser();
    const { isAlerted } = useAlert();
    const { isConfirmed } = useConfirm();
    const [isItemMenuOpen, setIsItemMenuOpen] = useState(false);

    const onLongPress = useLongPress(
        () => {
            if (navigator.vibrate) {
                navigator.vibrate(LONG_PRESS_VIBRATE);
            }
            if (onItemLongPress) {
                onItemLongPress();
            } else {
                setIsItemMenuOpen(true);
            }
        },
        {
            threshold: 500,
        }
    );

    if (!listData) return null;

    const onCheckItem = async () => {
        if (isSavedItemsMode && onSavedItemSelect) {
            onSavedItemSelect({ itemId: text, itemText: text });
            return;
        }

        await updateItemInDB({
            listId: listData.id,
            itemId: text,
            existingItems: listData.items,
            existingSavedItems: listData.savedItems,
            updates: {
                checked: !checked,
                missing: false,
            },
        });

        const allUncheckedItems = listData.items.filter(
            (item) => !item.checked && !item.missing
        );
        const willThisCheckCompleteTheList =
            !checked &&
            allUncheckedItems.length === 1 &&
            allUncheckedItems[0].text === text;

        if (willThisCheckCompleteTheList && setShowConfetti) {
            setShowConfetti(true);
        } else {
            setShowConfetti && setShowConfetti(false);
        }

        setIsItemMenuOpen(false);

        if (!isSavedItemsMode && !checked) {
            toast(
                <Toast
                    action={checked ? "uncheck" : "check"}
                    itemName={text}
                    text={` - ${checked ? "הוחזר לרשימה" : "סומן"}`}
                    onClick={async () => {
                        const updatedListData = await getListData({
                            listId: listData.id,
                        });
                        if (!updatedListData || updatedListData === null) {
                            await isAlerted("קרתה תקלה");
                            return;
                        }
                        updateItemInDB({
                            listId: updatedListData.listId,
                            itemId: text,
                            existingItems: updatedListData.items,
                            existingSavedItems: updatedListData.savedItems,
                            updates: {
                                checked: !!checked, // reverse the check state
                                missing: false,
                            },
                        });
                    }}
                    onClose={() => toast.dismiss(text)}
                />,
                {
                    toastId: text,
                }
            );
        }
    };

    const onMissingClick = async (missing: boolean) => {
        // const isPremiumUser = isPremium({ type: user?.userData?.accountType });
        // const isThereMissingItemAlready = listData.items.some(
        // (item) => item.missing
        // );
        // if (!isPremiumUser && isThereMissingItemAlready && !missing) {
        //     await isAlerted(
        //         `האופציה הזאת פתוחה בגרסת הפלוס.<br/>מוזמנים לנסות עם חודש התנסות בחינם<br/>ובלי שום התחייבות`
        //     );
        //     return;
        // }
        await updateItemInDB({
            listId: listData.id,
            itemId: text,
            existingItems: listData.items,
            existingSavedItems: listData.savedItems,
            updates: {
                checked: false,
                missing: !missing,
            },
        });

        const allUncheckedItems = listData.items.filter(
            (item) => !item.checked && !item.missing
        );
        const willThisCheckCompleteTheList =
            !missing &&
            allUncheckedItems.length === 1 &&
            allUncheckedItems[0].text === text;

        if (willThisCheckCompleteTheList && setShowConfetti) {
            setShowConfetti(true);
        } else {
            setShowConfetti && setShowConfetti(false);
        }

        setIsItemMenuOpen(false);
    };

    const onCategorySelect = async ({ categoryId }: { categoryId: string }) => {
        await updateItemInDB({
            listId: listData.id,
            itemId: text,
            existingItems: listData.items,
            existingSavedItems: listData.savedItems,
            updates: {
                categoryId,
            },
        });
        voteForCategory({
            categoryId,
            itemId: text,
        });
    };

    const onDeleteItem = async () => {
        if (
            await isConfirmed(
                `בטוח למחוק את הפריט מרשימת ${
                    isSavedItemsMode ? "השמורים" : "הקניות"
                }?`
            )
        ) {
            if (isSavedItemsMode) {
                deleteSavedItemFromDB({
                    itemId: text,
                    listId: listData.id,
                    existingSavedItems: listData.savedItems,
                    existingItems: listData.items,
                });
            } else {
                deleteItemFromDB({
                    itemId: text,
                    listId: listData.id,
                    existingItems: listData.items,
                });
            }
        }
    };

    // const onNameChangeSubmit = async ({ newName }: { newName: string }) => {
    //     if (!newName.trim() || newName.trim() === text) {
    //         setOpenEditDrawer(false);
    //         return;
    //     }

    //     const nameAlreadyExists = listData.items.some(
    //         (item) => item.text === newName
    //     );

    //     if (nameAlreadyExists) {
    //         await isAlerted("פריט בשם זה כבר קיים ברשימה");
    //         return;
    //     }

    //     const isNewTextNumeric = !isNaN(Number(newName));

    //     if (isNewTextNumeric) {
    //         await isAlerted("שם הפריט לא יכול להיות ספרות בלבד");
    //         return;
    //     }

    //     const isNewTextListWord = newName === "רשימה";
    //     if (isNewTextListWord) {
    //         await isAlerted('שם הפריט לא יכול להיות "רשימה"');
    //         return;
    //     }

    //     await updateItemInDB({
    //         listId: listData.id,
    //         itemId: text,
    //         existingItems: listData.items,
    //         existingSavedItems: listData.savedItems,
    //         updates: {
    //             text: newName,
    //         },
    //     });
    // };

    const { type } = listData;
    const isPrimaryList = type === LIST_TYPES.primary;
    const unitText = getUnitShortText(unit).substring(
        0,
        getUnitShortText(unit).includes('"') ? 3 : 2
    );
    const isQuantitnyTextLong =
        (quantity && quantity.toString().length > 1) || unitText.length > 1;

    const disabledSelect = isSavedItemsMode && isInListAlready;

    return (
        <>
            <li
                className={`flex cursor-pointer items-center justify-between min-h-[3.95rem] py-3 pr-1.5 w-full ${
                    disabledSelect
                        ? "bg-slate-200/40"
                        : isSelectedSavedItem
                        ? "bg-green-50/70 rounded-md"
                        : checked || missing
                        ? "opacity-30"
                        : ""
                } ${isSavedItemsMode ? "!pr-3 pl-1.5" : ""} gap-1`}
            >
                <button
                    className={`${
                        isSavedItemsMode &&
                        isInListAlready &&
                        isQuantitnyTextLong
                            ? "w-[59%]"
                            : isItemMenuOpen
                            ? "w-[43%]"
                            : isPrimaryList
                            ? quantity
                                ? missing
                                    ? "w-[49%]"
                                    : "w-[59%]"
                                : "w-[75%]"
                            : "w-[85%]"
                    } lg:w-[90%] flex group gap-0 items-center h-full focus:outline-none`}
                    {...onLongPress()}
                    onClick={
                        disabledSelect
                            ? () => isAlerted("הפריט נמצא כבר ברשימת הקניות")
                            : onCheckItem
                    }
                >
                    <CheckBox
                        checked={
                            checked || isSelectedSavedItem || disabledSelect
                        }
                        disabled={disabledSelect}
                    />
                    <div
                        className={`inline-flex flex-col lg:flex-row group-disabled:text-slate-400 text-start cursor-pointer break-keep leading-[34px] max-w-[80vw] h-full transition-all ${
                            // className={`inline-flex gap-1 group-disabled:text-slate-400 text-start cursor-pointer break-keep leading-[34px] items-center max-w-[80vw] whitespace-nowrap h-full overflow-auto transition-all ${
                            isSelectedSavedItem && !disabledSelect
                                ? "font-semibold"
                                : checked
                                ? "text-slate-800 line-through"
                                : ""
                        } ${
                            isItemMenuOpen
                                ? "w-20"
                                : isQuantitnyTextLong
                                ? "w-40"
                                : quantity || missing
                                ? "w-48"
                                : "w-64"
                        } no-select lg:w-[90%] scrollbar`}
                    >
                        <span
                            className={`[&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar]:h-1.5 [&::-webkit-scrollbar-thumb]:rounded-xl [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 block whitespace-nowrap overflow-auto w-full`}
                        >
                            {text}{" "}
                        </span>
                        {comments ? (
                            <span className="text-lg lg:min-w-[7rem] text-slate-700">
                                {/* <span className="inline-flex items-center gap-1 text-lg lg:min-w-[7rem] text-slate-700"> */}
                                {/* <ExclamationCircleIcon className="w-5 h-5 mt-1 shrink-0 text-slate-500" />{" "} */}
                                <span className="">{comments}</span>
                            </span>
                        ) : null}
                    </div>
                </button>

                {isItemMenuOpen ? (
                    <div
                        className={`${
                            isSavedItemsMode ? "" : "bg-white"
                        } flex flex-row-reverse parent-fade-in-left items-center gap-2 text-slate-700 no-select`}
                    >
                        <button
                            className="w-8"
                            onClick={() => setIsItemMenuOpen(false)}
                        >
                            <XMarkIcon className="mx-auto w-7 h-7" />
                        </button>
                        {isSavedItemsMode ? null : (
                            <button
                                type="button"
                                onClick={() => onMissingClick(missing)}
                                className={`-ml-1 w-[4rem] text-base h-10 rounded-full bg-slate-300/20 hover:bg-slate-300/80 transition-colors  inline-flex font-medium items-center justify-center`}
                            >
                                חסר
                            </button>
                        )}
                        <button
                            onClick={onDeleteItem}
                            className="bg-slate-300/20 lg:hover:bg-slate-300/80 transition-colors p-2 rounded-full"
                        >
                            <TrashIcon className="w-6 h-6" />
                        </button>
                        <button
                            className="p-2 rounded-full bg-slate-300/20 lg:hover:bg-slate-300/80 transition-colors"
                            onClick={() => {
                                if (onEditClick) {
                                    onEditClick();
                                    setIsItemMenuOpen(false);
                                }
                            }}
                        >
                            <PencilIcon className="w-6 h-6" />
                        </button>
                    </div>
                ) : (
                    <div className={`flex items-center gap-1 no-select`}>
                        {quantity ? (
                            <div
                                className={`${
                                    isQuantitnyTextLong ? "w-12" : "w-8"
                                } text-center text-xl text-slate-500 font-poppins lg:w-16`}
                            >
                                {quantity}
                                {unit ? (
                                    <span className="text-base text-slate-400 font-assistant pr-0.5">
                                        {unitText}
                                    </span>
                                ) : null}
                            </div>
                        ) : null}
                        {/* {disabledSelect ? (
                        <div className="flex text-sm gap-1 ml-1.5 text-slate-600 font-medium items-center">
                            <span>ברשימה</span>
                        </div>
                    ) : null} */}
                        {checked ||
                        // !isPrimaryList ||
                        isSavedItemsMode ||
                        !missing ? null : (
                            <button
                                type="button"
                                onClick={() => onMissingClick(missing)}
                                className={`w-[4rem] text-base h-10 rounded-full text-red-600 inline-flex font-medium items-center justify-center`}
                            >
                                חסר
                            </button>
                        )}
                        <SelectCategoryPopup
                            show={isPrimaryList}
                            button={
                                <div
                                    className={`${
                                        checked ? "" : " m-auto rounded-full"
                                    } text-2xl w-8 h-8 p-1 flex items-center justify-center`}
                                >
                                    {
                                        getCategoryById({
                                            id: categoryId,
                                        }).icon
                                    }
                                </div>
                            }
                            categoryOptions={Object.values(CATEGORIES)}
                            onSelect={onCategorySelect}
                        />
                        <button
                            type="button"
                            onClick={() => setIsItemMenuOpen(true)}
                            className={`text-slate-500 w-5 h-5`}
                        >
                            <EllipsisVerticalIcon className="" />
                        </button>
                    </div>
                )}
            </li>
            {/* <PartialEditItemModal
                open={openEditDrawer}
                setOpen={setOpenEditDrawer}
                itemText={text}
                onNameChangeSubmit={onNameChangeSubmit}
                onDeleteItem={onDeleteItem}
            /> */}
        </>
    );
}

// const PartialEditItemModal = ({
//     open,
//     setOpen,
//     itemText,
//     onNameChangeSubmit,
//     onDeleteItem,
// }: {
//     open: boolean;
//     setOpen: React.Dispatch<React.SetStateAction<boolean>>;
//     itemText: string;
//     onNameChangeSubmit: ({ newName }: { newName: string }) => void;
//     onDeleteItem: () => void;
// }) => {
//     const [newName, setNewName] = useState(itemText);
//     const [deleteDisabled, setDeleteDisabled] = useState(true);

//     const inputRef = React.useRef<HTMLInputElement>(null);

//     useEffect(() => {
//         if (open) {
//             inputRef.current?.focus();
//         }
//         setTimeout(() => {
//             setDeleteDisabled(false);
//         }, 1000);
//     }, [open]);

//     return (
//         <Transition show={open}>
//             <Dialog
//                 dir="rtl"
//                 as="div"
//                 className="font-assistant relative z-50"
//                 // onClose={() => setOpenEditDrawer(false)}
//                 onClose={() => {}}
//             >
//                 <Transition.Child
//                     as={Fragment}
//                     enter="ease-out duration-300"
//                     enterFrom="opacity-0"
//                     enterTo="opacity-100"
//                     leave="ease-in duration-200"
//                     leaveFrom="opacity-100"
//                     leaveTo="opacity-0"
//                 >
//                     <div className="fixed inset-0 bg-black/25" />
//                 </Transition.Child>
//                 <div className="fixed inset-0 overflow-y-auto">
//                     <div className="flex min-h-full items-center justify-center p-4 text-center">
//                         <Transition.Child
//                             as={Fragment}
//                             enter="ease-out duration-300"
//                             enterFrom="opacity-0 scale-95"
//                             enterTo="opacity-100 scale-100"
//                             leave="ease-in duration-200"
//                             leaveFrom="opacity-100 scale-100"
//                             leaveTo="opacity-0 scale-95"
//                         >
//                             <Dialog.Panel
//                                 as="div"
//                                 className="w-full h-full flex flex-col justify-between gap-2 max-w-md transform overflow-hidden rounded-xl bg-white p-6 text-start align-middle shadow-xl transition-all"
//                             >
//                                 <Dialog.Title
//                                     as="div"
//                                     className="flex items-center justify-center"
//                                 >
//                                     <h3 className="text-xl font-semibold text-center pb-3">
//                                         עריכת הפריט
//                                     </h3>
//                                     <button
//                                         type="button"
//                                         className="h-7 w-7 absolute top-4 left-4"
//                                     >
//                                         <XIcon onClick={() => setOpen(false)} />
//                                     </button>
//                                 </Dialog.Title>
//                                 <form
//                                     className="flex flex-col gap-2"
//                                     onSubmit={(e) => {
//                                         e.preventDefault();
//                                         if (newName.trim() === itemText) {
//                                             setOpen(false);
//                                             return;
//                                         }
//                                         onNameChangeSubmit({
//                                             newName: newName.trim(),
//                                         });
//                                     }}
//                                 >
//                                     <div className="flex text-lg gap-1  h-12 items-center flex-row-reverse">
//                                         <input
//                                             ref={inputRef}
//                                             className="border-2 h-full text-slate-800 border-slate-200 rounded-lg p-2 w-full"
//                                             type="text"
//                                             value={newName}
//                                             onChange={(e) => {
//                                                 setNewName(e.target.value);
//                                             }}
//                                         />
//                                     </div>

//                                     <button
//                                         className="text-lg w-full mt-5 gap-2 font-medium border-[1.5px] border-green-500 bg-green-500 text-white disabled:bg-slate-300 disabled:border-slate-300 rounded-lg shadow-sm px-3 py-2 flex items-center justify-center"
//                                         type="submit"
//                                         disabled={!newName.trim()}
//                                     >
//                                         שמירה <CheckIcon className="w-5 h-5" />
//                                     </button>
//                                     <button
//                                         className="text-lg w-full mt-1 gap-2 font-medium border-[1.5px] border-red-500 bg-white text-red-500 rounded-lg shadow-sm px-3 py-2 flex items-center justify-center"
//                                         type="button"
//                                         disabled={deleteDisabled}
//                                         onClick={onDeleteItem}
//                                     >
//                                         מחיקה <TrashIcon className="w-5 h-5" />
//                                     </button>
//                                 </form>
//                             </Dialog.Panel>
//                         </Transition.Child>
//                     </div>
//                 </div>
//             </Dialog>
//         </Transition>
//     );
// };

export const CheckBox = ({
    checked = false,
    disabled = false,
}: {
    checked?: boolean;
    disabled?: boolean;
}) => {
    return (
        <div
            className={`${
                disabled
                    ? "bg-slate-200"
                    : checked
                    ? "border-green-500 bg-green-500"
                    : "border-slate-400/70 bg-white"
            }  rounded-[0.25rem] shrink-0 w-5 border-[1.4px] ml-3 h-5 flex items-center justify-center`}
        >
            {checked && (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 13l4 4L19 7"
                    />
                </svg>
            )}
        </div>
    );
};

const Toast = ({
    itemName,
    text,
    onClick,
    onClose,
    action,
}: {
    itemName: string;
    text: string;
    action: "check" | "uncheck";
    onClick: () => void;
    onClose: () => void;
}) => {
    return (
        <div className="text-lg flex items-center justify-between gap-4 font-assistant h-4 w-full mx-auto bg-transparent text-slate-50">
            <div className="h-full w-full flex items-center justify-between">
                <div className="h-full w-full flex items-center">
                    {action === "check" ? (
                        <CheckIcon className="w-5 h-5 text-green-400 ml-2 stroke-[2.5]" />
                    ) : (
                        <ArrowUturnRightIcon className="w-5 h-5 text-slate-100 ml-2" />
                    )}
                    <span className="block font-semibold mx-1 max-w-[10rem] truncate">
                        {itemName}
                    </span>
                    {text}
                </div>
                <button
                    onClick={() => {
                        onClick();
                        onClose();
                    }}
                    className="text-blue-200 underlin font-medium w-10 shrink-0"
                >
                    ביטול
                </button>
            </div>
            <button onClick={onClose} className="flex items-center shrink-0">
                <XMarkIcon className="w-5 h-5 shrink-0" />
            </button>
        </div>
    );
};
