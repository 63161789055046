import useJoinParams from "hooks/useJoinParams";
import { useEffect } from "react";
import { registerToBotURL, registerToSharedListURL } from "../constants";

export default function Join() {
    const { listId } = useJoinParams();

    useEffect(() => {
        if (!listId) {
            window.location.replace(encodeURI(registerToBotURL));
        } else {
            window.location.replace(
                encodeURI(registerToSharedListURL + listId.toString())
            );
        }
    }, [listId]);

    return (
        <div
            dir="ltr"
            className="font-poppins flex text-center justify-center  text-3xl w-full h-screen"
        >
            <div className="mt-40 lg:mt-40">Joining Sally 🥁</div>
        </div>
    );
}
